import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ca5e9d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sp-header text-center"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "flex pc-first-layout"
};
const _hoisted_5 = {
  class: "flex-grow-1"
};
import { ref, provide } from "vue";
import AsideView from "./components/AsideView.vue";
import FooterView from "./components/FooterView.vue";
import hamburgerIcon from "@/assets/img/pc-first/hamburger-icon.svg";
import { useRoute, useRouter } from "vue-router";
import logo from "@/assets/img/logo.png";
import { onMounted } from "vue";
import { onBeforeUnmount } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const isShowMenu = ref(false);
    function closeMenu() {
      isShowMenu.value = false;
    }
    provide("closeMenu", closeMenu);
    function onResize(e) {
      if (isShowMenu.value && e.target.document.documentElement.clientWidth > 966) {
        closeMenu();
      }
    }
    onMounted(() => {
      window.addEventListener("resize", onResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });
    const disabledSwitchCreator = ref(false);
    function toggleDisabledSwitchCreator(disabled) {
      disabledSwitchCreator.value = disabled;
    }
    provide("toggleDisabledSwitchCreator", toggleDisabledSwitchCreator);
    const route = useRoute();
    function toHomePage() {
      router.push({
        path: "/"
      });
      if (closeMenu && typeof closeMenu === "function") {
        closeMenu();
      }
    }
    return (_ctx, _cache) => {
      const _component_el_drawer = _resolveComponent("el-drawer");
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", _hoisted_1, [_createElementVNode("button", {
        class: "pointer sp-show-menu-btn",
        onClick: _cache[0] || (_cache[0] = () => isShowMenu.value = !isShowMenu.value)
      }, [_createElementVNode("img", {
        src: _unref(hamburgerIcon),
        alt: ""
      }, null, 8, _hoisted_2)]), _createElementVNode("img", {
        src: _unref(logo),
        alt: "",
        class: "logo pointer",
        onClick: toHomePage
      }, null, 8, _hoisted_3), _createVNode(_component_el_drawer, {
        modelValue: isShowMenu.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => isShowMenu.value = $event),
        size: "240",
        direction: "ltr",
        "append-to-body": "",
        "custom-class": "aside-drawer",
        "modal-class": "aside-drawer-modal"
      }, {
        default: _withCtx(() => [_createVNode(AsideView, {
          class: "pc-aside",
          "disabled-switch-creator": disabledSwitchCreator.value
        }, null, 8, ["disabled-switch-creator"])]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("section", _hoisted_4, [_createVNode(AsideView, {
        class: "pc-aside",
        "disabled-switch-creator": disabledSwitchCreator.value
      }, null, 8, ["disabled-switch-creator"]), _createElementVNode("main", {
        class: _normalizeClass(["flex flex-column flex-grow-1 padding-32 main", {
          'no-padding': !_unref(route).meta.pcFirst
        }])
      }, [_createElementVNode("section", _hoisted_5, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: 0
        })) : _createCommentVNode("", true)]),
        _: 1
      })]), _createVNode(FooterView)], 2)])], 64);
    };
  }
};