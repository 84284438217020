import en from "./en.json"
import ja from "./ja.json"

const messages = {
  en,
  ja,
}

export function getLocale() {
  let locale = localStorage.getItem("i18n-locale")
  if (!(locale && ["ja", "en"].indexOf(locale) !== -1)) {
    locale = navigator.language.startsWith("ja") ? "ja" : "en"
    localStorage.setItem("i18n-locale", locale)
  }
  return locale
}

export function getOutlineError(needJudgeUserType = false) {
  return messages[getLocale(needJudgeUserType)].error.outline_error
}

export function getErrorTitle(needJudgeUserType = false) {
  return messages[getLocale(needJudgeUserType)].error.title
}

export function getErrorOK(needJudgeUserType = false) {
  return messages[getLocale(needJudgeUserType)].error.sure
}

export function getLoginError(isLuRegister = false) {
  return messages[getLocale(false, isLuRegister)].error.login_error
}

export function getValidateRequired() {
  return messages[getLocale()].form_validate.required
}
export default messages
