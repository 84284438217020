import { getRequest, postRequest, putRequest } from "./base/request"

// ユーザのプロフィール情報を取得する
export const requestGetUserProfile = () => {
  return getRequest("/user/profile", {}, false)
}

// ユーザのプロフィール情報を変更する
export const requestPutUserProfile = (params) => {
  return putRequest("/user/profile", params, true, true)
}

// アバターをアップロードする
export const requestPostUserAvatarUpload = (param) => {
  return postRequest("/user/avatar/upload", param)
}

// 購入履歴情報を取得する
export const requestGetUserPurchased = () => {
  return getRequest("/user/purchased", {}, false)
}

// 販売履歴情報を取得する
export const requestGetUserSelled = () => {
  return getRequest("/user/selled", {}, false)
}

// ユーザ詳細情報を取得する
export const requestGetUser = (id) => {
  return getRequest("/user/" + id, {})
}

// ユーザ販売中作品一覧情報を取得する
export const requestGetUserSelling = (id) => {
  return getRequest("/user/" + id + "/selling", {})
}

// 登録済カード情報を取得する
export const requestGetUserCard = () => {
  return getRequest("/user/card", {})
}
// イベント一覧取得
export const requestGetUserEvent = () => {
  return getRequest("/user/events", {}, false)
}

// ユーザのお気に入りイベント一覧取得
export const requestGetUserFavoriteEvent = () => {
  return getRequest("/user/favorite/events", {}, false)
}

// 作品一覧取得
export const requestGetUserEventArtworks = (id) => {
  return getRequest(`/user/event/${id}/artworks`, {}, false)
}

export function requestGetUserTickets() {
  return getRequest("/user/tickets", {}, false)
}

export function requestGetNotification() {
  return getRequest("/notifications", {}, false)
}

export function requestPutUserSetting(data) {
  return putRequest("/user/setting", data, false)
}

export function requestGetUserSetting() {
  return getRequest("/user/setting", null, false)
}
