import { defineStore } from "pinia"
import { useLocalStorage } from "@vueuse/core"

export const useUserStore = defineStore("user", {
  state: () => ({
    isCreator: useLocalStorage("isCreator", false),
    isLogin: useLocalStorage("isLogin", false),
    creatorId: useLocalStorage("creatorId", ""),
    registerEmail: useLocalStorage("registerEmail", ""),
    userInfo: useLocalStorage("userInfo", {}),
    creatorInfo: useLocalStorage("creatorInfo", {}),
    managedCreators: useLocalStorage("managedCreators", []),
    currentManagedCreator: useLocalStorage("currentManagedCreator", {}),
  }),
})
