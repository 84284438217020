import axios, { AxiosError, AxiosResponse } from "axios"
import { Toast, Dialog } from "vant"
import { clearLoginStatus, getUserProfile } from "@/utils/tool"
import { login } from "./login_signup_logout"
import { getLocale, getOutlineError, getErrorTitle, getErrorOK } from "@/i18n"
import router from "@/router"
import { ElMessage } from "element-plus"
import { useAppStore } from "@/store/modules/app"
import { useUserStore } from "@/store/modules/user"

const defaultInstance = axios.create()
defaultInstance.defaults.timeout = 60 * 1000
defaultInstance.defaults.headers["X-Requested-With"] = "XMLHttpRequest"
defaultInstance.defaults.headers.post["Content-Type"] =
  "application/json;application/x-www-form-urlencoded;charset=utf8;multipart/form-data"
defaultInstance.defaults.baseURL = process.env.VUE_APP_BASE_URL

defaultInstance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      `Bearer ${localStorage.getItem("token")}` || ""
    config.headers["Accept-Language"] = getLocale()
    return config
  },
  (error) => {
    return error
  },
)

defaultInstance.interceptors.response.use(
  (response) => {
    return response.data?.data || null
  },
  (error) => {
    throw error
  },
)

export const abortController = {
  value: new AbortController(),
  abort: () => {
    abortController.value.abort()
    abortController.value = new AbortController()
  },
}

function outlineErrorHandler() {
  Toast(getOutlineError())
}

/**
 * client error handler
 * @param {AxiosError} error
 */
function clientErrorHandler(error) {
  switch (error.code) {
    case "ERR_NETWORK":
      outlineErrorHandler()
      break
    default:
      break
  }
}

/**
 *
 * @param {AxiosResponse} response
 */
async function notFoundErrorHandler() {
  Dialog.alert({
    title: getErrorTitle(true),
    message: "404 Not Found",
    confirmButtonColor: "#000000",
    confirmButtonText: getErrorOK(true),
    beforeClose: async () => {
      router.replace({ path: "/" }).then(() => {
        window.location.reload()
      })
      return true
    },
  })
}

/**
 *
 * @param {AxiosResponse} response
 */
function toMaintenancePage(response) {
  const start_date_time = response.data?.start_datetime
  const end_date_time = response.data?.end_datetime
  router.replace({
    path: "/maintenance",
    query: {
      start_date_time,
      end_date_time,
    },
  })
}

/**
 *
 * @param {AxiosResponse} response
 */
function showResponseErrorMessage(response) {
  if (response.data?.message) {
    Dialog.alert({
      title: getErrorTitle(true),
      message: response.data.message,
      confirmButtonColor: "#000000",
      confirmButtonText: getErrorOK(true),
      className: "error-dialog",
      overlayClass: "error-overlay",
    })
  }
}

/**
 *
 */
async function noAuthHandler(response) {
  response.data?.message && ElMessage.error(response.data.message)
  const userStore = useUserStore()
  if (userStore.isLogin) {
    await getUserProfile()
  } else {
    clearLoginStatus()
  }
  router.replace("/")
}

/**
 *
 * @param {AxiosResponse} response
 */
function responseErrorHandler(response) {
  const { status } = response
  switch (status) {
    case 401:
      clearLoginStatus()
      setTimeout(() => {
        login()
      })
      break
    case 403:
      noAuthHandler(response)
      break
    case 404:
      notFoundErrorHandler(response)
      break
    case 502:
    case 503:
      toMaintenancePage(response)
      break
    default:
      showResponseErrorMessage(response)
      break
  }
}

/**
 * default error handler
 * @param {AxiosError} error axios error
 */
function defaultErrorHandler(error) {
  const response = error.response
  if (!response) {
    clientErrorHandler(error)
  } else {
    responseErrorHandler(response)
  }
  throw error
}

export function errorHandler(
  error,
  specialErrorHandler,
  enableDefaultErrorHandler = true,
) {
  if (
    !(
      specialErrorHandler &&
      typeof specialErrorHandler === "function" &&
      specialErrorHandler(error)
    ) &&
    enableDefaultErrorHandler
  ) {
    defaultErrorHandler(error)
  }
}

/**
 * business request wrapper
 * @param {() => Promise<unknown>} businessHandler
 * @param {boolean | (error: AxiosError) => boolean} specialErrorHandler default false
 * @param {boolean} loading default true
 * @param {boolean} abortRequestAfterError default true
 * @returns
 */
export async function requestWrapper(
  businessHandler,
  specialErrorHandler = false,
  loading = true,
  abortRequestAfterError = true,
) {
  if (!navigator.onLine) {
    outlineErrorHandler()
    return
  }
  const appStore = useAppStore()
  loading && appStore.toggleGlobalLoading(true)
  abortRequestAfterError && appStore.toggleAbortRequest(true)
  try {
    return await businessHandler()
  } catch (error) {
    abortRequestAfterError && appStore.toggleAbortRequest(false)
    if (specialErrorHandler && typeof specialErrorHandler === "boolean") {
      throw error
    }
    errorHandler(error, specialErrorHandler)
  } finally {
    loading && appStore.toggleGlobalLoading(false)
  }
}

function getRequestConfig(config = {}) {
  const defaultConfig = {}
  const appStore = useAppStore()
  if (appStore.abortRequest) {
    defaultConfig.signal = abortController.value.signal
  }
  return { ...defaultConfig, ...config }
}

export function getRequest(
  url,
  params,
  config = {},
  instance = defaultInstance,
) {
  return instance.get(url, {
    ...getRequestConfig(config),
    params,
  })
}

export function postRequest(
  url,
  data,
  config = {},
  instance = defaultInstance,
) {
  return instance.post(url, data, getRequestConfig(config))
}

export function deleteRequest(
  url,
  params,
  data,
  config = {},
  instance = defaultInstance,
) {
  return instance.delete(url, {
    ...getRequestConfig(config),
    params,
    data,
  })
}

export function putRequest(url, data, config = {}, instance = defaultInstance) {
  return instance.put(url, data, getRequestConfig(config))
}
