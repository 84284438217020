import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2e8ef7fb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "switch-language-container pointer"
};
import { useI18n } from "vue-i18n";
export default {
  __name: 'index',
  setup(__props) {
    const {
      locale
    } = useI18n({
      useScope: "global"
    });
    const switchLocale = lang => {
      locale.value = lang;
      localStorage.setItem("i18n-locale", lang);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
        class: _normalizeClass(["lang", {
          'highlight-lang': _unref(locale) === 'ja'
        }]),
        onClick: _cache[0] || (_cache[0] = $event => switchLocale('ja'))
      }, " JP ", 2), _createElementVNode("span", {
        class: _normalizeClass(["lang", {
          'highlight-lang': _unref(locale) === 'en'
        }]),
        onClick: _cache[1] || (_cache[1] = $event => switchLocale('en'))
      }, " EN ", 2), _createElementVNode("div", {
        class: _normalizeClass(["highlight", {
          'highlight-en': _unref(locale) === 'en'
        }])
      }, null, 2)]);
    };
  }
};