import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ba6a8724"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "receive-email-container"
};
const _hoisted_2 = ["src"];
import { useAppStore } from "@/store/modules/app";
import { storeToRefs } from "pinia";
import receiveEmailSettingImg from "@/assets/img/receive_email_setting.svg";
import { ref } from "vue";
import { requestPutUserSetting } from "@/api/user";
import ButtonView from "./ButtonView.vue";
export default {
  __name: 'ReceiveEmailSettingDialog',
  setup(__props) {
    const appStore = useAppStore();
    const {
      showReceiveEmailSettingDialog
    } = storeToRefs(useAppStore());
    const receiveEmail = ref(true);
    async function updateReceiveEmailSetting() {
      try {
        appStore.toggleGlobalLoading(true);
        await requestPutUserSetting({
          receive_email: receiveEmail.value
        });
        appStore.closeReceiveEmailSettingDialog();
      } finally {
        appStore.toggleGlobalLoading(false);
      }
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(_Popup), {
        show: _unref(showReceiveEmailSettingDialog),
        class: "receive-email-popup",
        "close-on-click-overlay": false
      }, {
        default: _withCtx(() => [_createElementVNode("section", _hoisted_1, [_createElementVNode("main", null, [_createElementVNode("img", {
          src: _unref(receiveEmailSettingImg),
          alt: ""
        }, null, 8, _hoisted_2), _createVNode(_unref(_Checkbox), {
          modelValue: receiveEmail.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => receiveEmail.value = $event),
          shape: "square"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("setting.receive_email_setting_checkbox_label")), 1)]),
          _: 1
        }, 8, ["modelValue"])]), _createElementVNode("footer", null, [_createVNode(ButtonView, {
          round: "",
          class: "w-full",
          onClick: updateReceiveEmailSetting
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("setting.receive_email_setting_btn")), 1)]),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};