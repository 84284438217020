import "core-js/modules/es.array.push.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2831f0f1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-info"
};
const _hoisted_2 = ["src"];
import { inject } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
export default {
  __name: 'NavItem',
  props: {
    iconUrl: {
      type: String,
      default: ""
    },
    activeIconUrl: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: ""
    },
    cursor: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const route = useRoute();
    const props = __props;
    const closeMenu = inject("closeMenu");
    function toPage() {
      if (props.path) {
        if (props.path.startsWith("http")) {
          window.top.open(props.path);
        } else {
          router.push({
            path: props.path
          });
        }
        if (closeMenu && typeof closeMenu === "function") {
          closeMenu();
        }
      }
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["nav-item", {
          highlight: __props.path === _unref(route).meta.highlightPath,
          pointer: __props.path || __props.cursor,
          'cursor-not-allowed': !__props.path && !__props.cursor
        }]),
        onClick: toPage
      }, [_createElementVNode("div", _hoisted_1, [__props.iconUrl ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: __props.path === _unref(route).meta.highlightPath ? __props.activeIconUrl : __props.iconUrl,
        alt: ""
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(__props.name), 1)]), _renderSlot(_ctx.$slots, "suffix")], 2);
    };
  }
};