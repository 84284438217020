import { getRequest, postRequest, putRequest } from "./base/request"

/***
 **************** creator ****************
 */
export const requestGetCreatorDetail = (id) => {
  return getRequest("/creator/" + id, {}, false)
}
export const requestGetCreatorWorks = (id) => {
  return getRequest("/creator/" + id + "/artworks", {}, false)
}
export const requestGetCreatorProfile = (params) => {
  return getRequest("/creator/profile", params, false)
}
// 自分の店舗基本情報を取得する
export const requestGetOwnCreator = () => {
  return getRequest("/creator", {}, false)
}
// 店舗情報を登録する
export const requestPutEditCreatorInfo = (params) => {
  return putRequest("/creator/profile", params, false)
}
// 店舗写真をアップロードする
export const requestPostCreatorUpload = (param) => {
  return postRequest("/creator/upload", param, false)
}
// 自分の店舗参加してるイベント一覧を取得する
export const requestGetCreatorEvents = (params) => {
  return getRequest("/creator/events", params, false, true)
}
// 自分の店舗の作品一覧を取得する
// sort: 表示順 [1:いいね降順, 2:販売量降順, 3:発行時間降順]。指定しない場合、発行時間降順で検索する
export const requestGetCreatorArtworks = (params) => {
  return getRequest("/creator/artworks", params, false)
}

export function requestGetCreatorEventsCreated(params) {
  return getRequest("/creator/events/created", params, false, true)
}

// クリエイター一覧を取得する
export function requestGetCreators() {
  return getRequest("/creators", {}, false, true)
}
