import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-55f7b854"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["disabled"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export default {
  __name: 'ButtonView',
  props: {
    type: {
      default: "submit",
      validator(value) {
        return ["submit", "cancel", "add", "danger"].includes(value);
      }
    },
    preIconLink: {
      type: String,
      required: false,
      default: null
    },
    subIconLink: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    // small | default
    size: {
      type: String,
      default: "default"
    },
    disabledRound: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        class: _normalizeClass([__props.type, {
          round: __props.round
        }, __props.size, {
          'disabled-round': __props.disabledRound
        }, {
          highlight: __props.highlight
        }]),
        disabled: __props.disabled
      }, [__props.preIconLink ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "pre-icon",
        src: __props.preIconLink,
        alt: ""
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), __props.subIconLink ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "sub-icon",
        src: __props.subIconLink,
        alt: ""
      }, null, 8, _hoisted_3)) : _createCommentVNode("", true)], 10, _hoisted_1);
    };
  }
};