import { Startrail } from "@startbahn/startrail-sdk-js"
import { getLocale } from "@/i18n"
import { storeToRefs } from "pinia"
import { useUserStore } from "@/store/modules/user"
import { postRequest } from "./request_v2"
import { useAppStore } from "@/store/modules/app"
import { clearLoginStatus, getUserProfile, isSpecifyDevice } from "@/utils/tool"
import { ElMessage } from "element-plus"
import { getLoginError } from "@/i18n"
import router from "@/router/index"

function getLoginProviderListByDevice() {
  if (isSpecifyDevice("Android")) {
    return ["google", "email_password"]
  }
  if (isSpecifyDevice("iOS")) {
    return ["google", "email_password", "facebook", "twitter", "apple"]
  }
  return ["google", "email_password", "facebook", "twitter", "line", "apple"]
}

/**
 *
 * @param {'google' | 'email_password' | 'facebook' | 'twitter' | 'line' | 'apple' } loginProvider
 * @returns
 */
export function getLoginConfig(loginProvider) {
  return {
    lang: getLocale(),
    authAction: { login: true, signup: false },
    wallet: "startrail",
    loginProvider: loginProvider
      ? [loginProvider]
      : getLoginProviderListByDevice(),
    withModal: true,
    customUi: {
      logoUrl: `${window.location.origin}/login-logo.png`,
      serviceName: "Startrail",
      words: {
        ja: {
          modal: {
            continueLogin: "前回の{verifier}アカウントを使う", // {verifier}は任意ですが、文言の中に存在していれば選択したログイン方法の名前へと自動的に置き換わります。
            termsConditions: "あなたの termsConditions",
            termsConditionsLinkUrl: "あなたの termsConditionsLinkUrl",
          },
          signPopup: {
            title: "ユーザログイン",
            requestFrom: "ユーザログイン",
            dataToSign: "ユーザログイン",
            confirm: "OK",
            cancel: "Cancel",
          },
          emailAuthPopup: {
            titleLogin: "ユーザログイン",
            titleSignup: "ユーザログイン",
          },
        },
        en: {},
      },
    },
    env: process.env.VUE_APP_ENV,
    apiPath: process.env.VUE_APP_API_PATH,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0TorusConfigKey: process.env.VUE_APP_AUTH0_TORUS_CONFIG_KEY,
  }
}

function postAuthMessage(eoa) {
  return postRequest("/auth/message", { eoa })
}

function postAuth(data) {
  return postRequest("/auth", data)
}

/**
 * 1:メール, 2:Google, 3:MetaMask, 4:AppleID, 5:Line, 6:Facebook, 7:Twitter
 * @param {'google' | 'email_password' | 'facebook' | 'twitter' | 'line' | 'apple'} loginType
 */
function get_login_type(loginType) {
  switch (loginType) {
    case "email_password":
      return 1
    case "google":
      return 2
    case "apple":
      return 4
    case "line":
      return 5
    case "facebook":
      return 6
    case "twitter":
      return 7
    default:
      return 1
  }
}

/**
 *
 * @param {'google' | 'email_password' | 'facebook' | 'twitter' | 'line' | 'apple' } loginProvider
 * @returns
 */
export async function login(loginProvider) {
  const appStore = useAppStore()
  let logoutInstance = null
  let loginInstance = null
  try {
    appStore.toggleGlobalLoading(true)
    logoutInstance = new Startrail(getBaseConfig())
    await logoutInstance.logout()
    clearLoginStatus()
    loginInstance = new Startrail(getLoginConfig(loginProvider))
    const response = await loginInstance.login()
    if (!response) {
      return "cancel"
    }
    const [eoa] = response
    const { id, message } = await postAuthMessage(eoa)
    const { prefix, signature } = await loginInstance.signMessage(message)
    const { email, typeOfLogin } = await loginInstance.getUserInfo()
    const { token } = await postAuth({
      id,
      prefix,
      signature,
      address: eoa,
      email,
      user_type: 1,
      login_type: get_login_type(typeOfLogin),
    })
    if (token) {
      const { registerEmail } = storeToRefs(useUserStore())
      localStorage.setItem("token", token)
      registerEmail.value = email
      await getUserProfile(true)
    }
  } catch (error) {
    ElMessage.error(getLoginError())
  } finally {
    appStore.toggleGlobalLoading(false)
    logoutInstance = null
    loginInstance = null
  }
}

function getMetaMaskConfig() {
  return {
    wallet: "metamask",
    env: process.env.VUE_APP_ENV,
    apiPath: process.env.VUE_APP_API_PATH,
  }
}

export async function mateMaskLogin() {
  const appStore = useAppStore()
  let logoutInstance = null
  let loginInstance = null
  try {
    appStore.toggleGlobalLoading(true)
    logoutInstance = new Startrail(getBaseConfig())
    await logoutInstance.logout()
    clearLoginStatus()
    loginInstance = new Startrail(getMetaMaskConfig())
    const [eoa] = await loginInstance.login()
    const { id, message } = await postAuthMessage(eoa)
    const { signature } = await loginInstance.signMessage(message)
    const { token } = await postAuth({
      id,
      signature,
      address: eoa,
      user_type: 1,
      login_type: 3,
    })
    if (token) {
      localStorage.setItem("token", token)
      await getUserProfile(true)
    }
  } catch (error) {
    console.error("MetaMask login error: ", error)
  } finally {
    appStore.toggleGlobalLoading(false)
    logoutInstance = null
    loginInstance = null
  }
}

function getEmailSignupConfig() {
  return {
    lang: getLocale(),
    authAction: { login: false, signup: true },
    wallet: "startrail",
    loginProvider: ["email_password"],
    withModal: true,
    callbackUrl: window.location.origin,
    customUi: {
      logoUrl: `${window.location.origin}/login-logo.png`,
      serviceName: "Startrail",
      words: {
        ja: {
          modal: {
            continueLogin: "前回の{verifier}アカウントを使う", // {verifier}は任意ですが、文言の中に存在していれば選択したログイン方法の名前へと自動的に置き換わります。
            termsConditions: "あなたの termsConditions",
            termsConditionsLinkUrl: "あなたの termsConditionsLinkUrl",
          },
          signPopup: {
            title: "ユーザの新規登録",
            requestFrom: "ユーザの新規登録",
            dataToSign: "ユーザの新規登録",
            confirm: "ok",
            cancel: "Cancel",
          },
          emailAuthPopup: {
            titleLogin: "ユーザの新規登録",
            titleSignup: "ユーザの新規登録",
          },
        },
        en: {},
      },
    },
    env: process.env.VUE_APP_ENV,
    apiPath: process.env.VUE_APP_API_PATH,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0TorusConfigKey: process.env.VUE_APP_AUTH0_TORUS_CONFIG_KEY,
  }
}

export async function signup() {
  let logoutInstance = null
  let signupInstance = null
  try {
    logoutInstance = new Startrail(getBaseConfig())
    await logoutInstance.logout()
    clearLoginStatus()
    signupInstance = new Startrail(getEmailSignupConfig())
    return await signupInstance.login()
  } finally {
    logoutInstance = null
    signupInstance = null
  }
}

export function getBaseConfig() {
  return {
    env: process.env.VUE_APP_ENV,
    apiPath: process.env.VUE_APP_API_PATH,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0TorusConfigKey: process.env.VUE_APP_AUTH0_TORUS_CONFIG_KEY,
  }
}

export async function logout() {
  const appStore = useAppStore()
  let logoutInstance = new Startrail(getBaseConfig())
  try {
    appStore.toggleGlobalLoading(true)
    return await logoutInstance.logout()
  } catch (error) {
    ElMessage.error("ログインに異常が発生しました。")
  } finally {
    appStore.toggleGlobalLoading(false)
    router.replace({
      path: "/",
    })
    setTimeout(() => {
      clearLoginStatus()
    }, 500)
    logoutInstance = null
  }
}

export function getTransferConfig() {
  const userInfoStr = localStorage.getItem("userInfo")
  if (userInfoStr) {
    const userInfo = JSON.parse(userInfoStr)
    if (userInfo.email) {
      return getLoginConfig()
    } else {
      return getMetaMaskConfig()
    }
  }
  return {}
}
