import { createRouter, createWebHistory } from "vue-router"
import { useUserStore } from "@/store/modules/user"
import { ElMessage } from "element-plus"
import { storeToRefs } from "pinia"
import { useAppStore } from "@/store/modules/app"
import { getLocale } from "@/i18n"
const routes = [
  {
    path: "/",
    name: "entry",
    redirect: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/entry/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
        meta: {
          userType: "user",
          activeName: "home",
        },
      },
      {
        path: "/home-event-list/:status",
        name: "home-event-list",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/home/home-event-list.vue"
          ),
        meta: {
          activeName: "home",
        },
      },
      {
        path: "/scan",
        name: "scan",
        component: () =>
          import(/* webpackChunkName: "scan" */ "../views/scan/index.vue"),
        meta: {
          userType: "user",
          activeName: "scan",
        },
      },
      {
        path: "/mine",
        name: "mine",
        component: () =>
          import(/* webpackChunkName: "mine" */ "../views/mine/index.vue"),
        meta: {
          activeName: "mine",
          userType: "user",
        },
      },
      {
        path: "/setting",
        name: "setting",
        component: () =>
          import(
            /* webpackChunkName: "mine" */ "../views/settingView/index.vue"
          ),
        meta: {
          activeName: "mine",
          userType: "user",
        },
      },
      {
        path: "/event-artworks",
        name: "event-artworks",
        component: () =>
          import(
            /* webpackChunkName: "mine" */ "../views/mine/event-artworks/index.vue"
          ),
        meta: {
          activeName: "mine",
          userType: "user",
          needLogin: "true",
        },
      },
      {
        path: "/work-detail",
        name: "work-detail",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "../views/work/work-detail/index.vue"
          ),
        meta: {
          activeName: "mine",
        },
      },
      {
        path: "/transfer",
        name: "transfer",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "../views/TransferView/index.vue"
          ),
        meta: {
          activeName: "mine",
        },
      },
      {
        path: "/register-success",
        name: "register-success",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register-success/index.vue"
          ),
        meta: {
          activeName: "mine",
        },
      },
      {
        path: "/event-detail",
        name: "event-detail",
        component: () =>
          import(/* webpackChunkName: "event" */ "../views/event/index.vue"),
      },
      {
        path: "/mine-sale-history",
        name: "mine-sale-history",
        component: () =>
          import(
            /* webpackChunkName: "mine" */ "../views/mine/history/index.vue"
          ),
        meta: {
          activeName: "mine",
          userType: "user",
          needLogin: true,
        },
      },
      {
        path: "/creator-detail",
        name: "creator-detail",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "../views/work/creator-detail/index.vue"
          ),
      },
      {
        path: "/payment-card-list",
        name: "payment-card-list",
        component: () =>
          import(
            /* webpackChunkName: "payment" */ "../views/payment/PaymentCardList.vue"
          ),
        meta: {
          activeName: "mine",
          needLogin: true,
        },
      },
      {
        path: "/payment-card-add",
        name: "payment-card-add",
        component: () =>
          import(
            /* webpackChunkName: "payment" */ "../views/payment/PaymentCardAdd.vue"
          ),
        meta: {
          activeName: "mine",
          needLogin: true,
        },
      },
      {
        path: "/purchase-confirmation",
        name: "purchase-confirmation",
        component: () =>
          import(
            /* webpackChunkName: "payment" */ "../views/payment/PurchaseConfirmation.vue"
          ),
        meta: {
          activeName: "mine",
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/home-more",
    name: "home-more",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/more/index.vue"),
    meta: {
      userType: "user",
    },
  },
  {
    path: "/home-search",
    name: "home-search",
    meta: {
      keepAlive: true,
      userType: "user",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/search/index.vue"),
  },
  {
    path: "/work-list",
    name: "work-list",
    component: () =>
      import(
        /* webpackChunkName: "work" */ "../views/work/work-list/index.vue"
      ),
    meta: {
      userType: "user",
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import(
        /* webpackChunkName: "mine" */ "../views/mine/contact-us/index.vue"
      ),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "../views/maintenance/index.vue"
      ),
  },
  {
    path: "/lp",
    name: "lp",
    component: () =>
      import(/* webpackChunkName: "lp" */ "../views/lp/index.vue"),
    meta: {
      isFullScreen: true,
    },
  },
  {
    path: "/404",
    name: "error-404",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/error/error-404.vue"),
  },

  {
    path: "/secret",
    name: "secret",
    component: () =>
      import(/* webpackChunkName: "secret" */ "../views/secret/SecretView.vue"),
    meta: {
      activeName: "mine",
    },
  },

  {
    path: "/secret/:qr_code",
    name: "secret-code-consume",
    component: () =>
      import(
        /* webpackChunkName: "secret" */ "../views/secret/SecretCodeConsume.vue"
      ),
    meta: {
      activeName: "mine",
    },
  },

  // pc first

  {
    path: "/event-create",
    name: "event-create",
    component: () =>
      import(/* webpackChunkName: "pc-first" */ "../views/pc-first/Event.vue"),
    meta: {
      highlightPath: "/event-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/event-edit/:id",
    name: "event-edit",
    component: () =>
      import(/* webpackChunkName: "pc-first" */ "../views/pc-first/Event.vue"),
    meta: {
      highlightPath: "/event-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/event-list",
    name: "event-list",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/EventList.vue"
      ),
    meta: {
      highlightPath: "/event-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/artwork-create",
    name: "artwork-create",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/Artwork.vue"
      ),
    meta: {
      highlightPath: "/artwork-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/artwork-edit/:id",
    name: "artwork-edit",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/Artwork.vue"
      ),
    meta: {
      highlightPath: "/artwork-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/artwork-list",
    name: "artwork-list",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/ArtworkList.vue"
      ),
    meta: {
      highlightPath: "/artwork-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/attachment-list",
    name: "attachmentList",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/attachment/AttachmentList.vue"
      ),
    meta: {
      highlightPath: "/attachment-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/attachment-create",
    name: "attachmentCreate",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/attachment/AttachmentCreate.vue"
      ),
    meta: {
      highlightPath: "/attachment-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/attachment-edit/:attachment_id",
    name: "attachmentEdit",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/attachment/AttachmentEdit.vue"
      ),
    meta: {
      highlightPath: "/attachment-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/creator-info",
    name: "creator-info",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/creator-info/index.vue"
      ),
    meta: {
      highlightPath: "/creator-info",
      pcFirst: true,
    },
  },
  {
    path: "/ticket-list",
    name: "ticketList",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/ticket/TicketList.vue"
      ),
    meta: {
      highlightPath: "/ticket-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/ticket-create",
    name: "ticketCreate",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/ticket/TicketCreate.vue"
      ),
    meta: {
      highlightPath: "/ticket-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/ticket-edit/:ticket_id",
    name: "ticketEdit",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/ticket/TicketEdit.vue"
      ),
    meta: {
      highlightPath: "/ticket-list",
      pcFirst: true,
      userType: "creator",
      needLogin: true,
    },
  },
  {
    path: "/ticket-qr-scan/:ticketKey",
    name: "ticketQRScan",
    component: () =>
      import(
        /* webpackChunkName: "pc-first" */ "../views/pc-first/TicketQRScan.vue"
      ),
    meta: {
      isFullScreen: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      path: "/",
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const { purchaseArtwork, paymentCard } = storeToRefs(useAppStore())
  /**
   * purchase-confirmation page
   * 处理支付中刷新、浏览器导航和关闭tab页面问题
   * 处于支付中和完成支付后刷新：回到作品详细页面
   */
  const purchasing = sessionStorage.getItem("purchasing")
  sessionStorage.removeItem("purchasing")

  const purchaseFinished = sessionStorage.getItem("purchase_finished")
  sessionStorage.removeItem("purchase_finished")

  const { needLogin, userType } = to.meta
  const { isLogin, isCreator } = useUserStore()
  if (userType === "user") {
    if (needLogin && !isLogin) {
      next({ path: "/404" })
      return
    }
  }
  if (userType === "creator" && (!isLogin || !isCreator)) {
    ElMessage.closeAll()
    ElMessage.warning("まずはクリエイター登録を完了してください")
    next(false)
    return
  }
  if (
    ["/payment-card-list", "/payment-card-add"].includes(to.path) &&
    Object.keys(purchaseArtwork.value).length === 0
  ) {
    next({ path: "/" })
    return
  }
  if (
    to.path === "/purchase-confirmation" &&
    (Object.keys(purchaseArtwork.value).length === 0 ||
      Object.keys(paymentCard.value).length === 0)
  ) {
    next({ path: "/" })
    return
  }
  const toWorkDetailMessage = {
    ja: "作品の詳細ページに戻る",
    en: "Return to the work details page",
  }
  if ((purchasing || purchaseFinished) && to.path !== "/work-detail") {
    ElMessage.info({
      message: toWorkDetailMessage[getLocale()],
      customClass: "to-work-detail-info",
    })
    if (purchaseArtwork.value.artwork_id) {
      next({
        path: "/work-detail",
        query: {
          id: purchaseArtwork.value.artwork_id,
          isFromScan: "1",
        },
      })
    } else {
      next({
        path: "/",
      })
    }
    return
  }

  next()
})

router.afterEach((to) => {
  const { purchaseArtwork, paymentCard } = storeToRefs(useAppStore())
  /**
   * 不处于支付流程的时候删除purchaseArtwork
   */
  if (
    ![
      "/payment-card-list",
      "/payment-card-add",
      "/purchase-confirmation",
    ].includes(to.path)
  ) {
    setTimeout(() => {
      purchaseArtwork.value = {}
    }, 100)
  }
  if (to.path !== "/purchase-confirmation") {
    setTimeout(() => {
      paymentCard.value = {}
    }, 100)
  }
})

export default router
