// compress image
import { Toast } from "vant"
import axios from "axios"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import dayjs from "dayjs"
import { storeToRefs } from "pinia"
import { useUserStore } from "@/store/modules/user"
import { useAppStore } from "@/store/modules/app"
import ja from "dayjs/locale/ja"
import en from "dayjs/locale/en"
import { requestGetNotification, requestGetUserSetting } from "@/api/user"
import JSEncrypt from "jsencrypt"
import * as THREE from "./three/three.module.min.js"
import { GLTFLoader } from "./three/jsm/loaders/GLTFLoader"
import { OrbitControls } from "./three/jsm/controls/OrbitControls"
import { RoomEnvironment } from "./three/jsm/environments/RoomEnvironment"

dayjs.extend(utc)
dayjs.extend(timezone)

export function compressImage(
  file,
  maxWidth = 500,
  maxHeight = 500,
  maxSize = 100,
  isThumbnail = false,
) {
  return new Promise((resolve, reject) => {
    const { name } = file
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const src = e.target.result
      let img = new Image()
      img.src = src
      img.onload = (e) => {
        let w = img.width
        let h = img.height
        const index = file?.name?.lastIndexOf(".")
        if (
          "svg".indexOf(file?.name?.toLowerCase().substring(index + 1)) !== -1
        ) {
          const canvas = document.createElement("canvas")
          if (w < maxWidth && h < maxHeight) {
            if (w > h) {
              const ratio = maxWidth / w
              w = maxWidth
              h = h * ratio
            } else {
              const ratio = maxHeight / h
              h = maxHeight
              w = w * ratio
            }
          }
          canvas.width = w
          canvas.height = h
          const canvasCtx = canvas.getContext("2d")
          canvasCtx.fillStyle = "#FFF"
          canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
          canvasCtx.drawImage(img, 0, 0)
          const newImg = new Image()
          newImg.src = canvas.toDataURL("image/jpeg", 1)
          newImg.onload = () => {
            let quality = 1
            let newFile
            do {
              newFile = compress(
                newImg,
                maxWidth,
                maxHeight,
                maxSize,
                name,
                isThumbnail,
                quality,
              )
              quality -= 0.1
            } while (newFile.size / 1024 > maxSize && quality > 0)
            return resolve(newFile)
          }
        } else {
          if (
            file.size / 1024 < maxSize &&
            w < maxWidth &&
            h < maxHeight &&
            "gif".indexOf(file?.name?.toLowerCase().substring(index + 1)) === -1
          ) {
            return resolve(file)
          }
          let quality = 1
          let newFile
          do {
            newFile = compress(
              img,
              maxWidth,
              maxHeight,
              maxSize,
              name,
              isThumbnail,
              quality,
            )
            quality -= 0.1
          } while (newFile.size / 1024 > maxSize && quality > 0)
          resolve(newFile)
        }
      }
      img.onerror = (e) => {
        reject(e)
      }
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })
}

// eslint-disable-next-line consistent-return
function compress(
  img,
  maxWidth = 500,
  maxHeight = 500,
  maxSize = 100,
  name,
  isThumbnail = false,
  quality = 0.9,
) {
  const w = img.width
  const h = img.height
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  let targetWidth = w
  let targetHeight = h
  const ratio = h / w
  if (targetWidth > maxWidth) {
    targetWidth = maxWidth
    targetHeight = ratio * targetWidth
  }
  if (targetHeight > maxHeight) {
    targetHeight = maxHeight
    targetWidth = targetHeight / ratio
  }
  canvas.width = targetWidth
  canvas.height = targetHeight

  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, targetWidth, targetHeight)
  ctx.drawImage(img, 0, 0, targetWidth, targetHeight)
  const base64 = canvas.toDataURL("image/jpeg", quality)
  const bytes = window.atob(base64.split(",")[1])
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: "image/jpeg" })
  const index = name?.lastIndexOf(".")
  const newName = isThumbnail
    ? `${name?.substring(0, index)}_thumbnail.jpg`
    : name
  const newFile = new File([blob], newName, { type: "image/jpeg" })
  return newFile
}

export const xssFilter = (html) => {
  if (!html) return ""
  html = html.replace(/<\s*\/?script\s*>/, "")
  html = html.replace(/javascript:[^'"]*/g, "")
  html = html.replace(/onerror\s*=\s*['"]?[^'"]*['"]?/g, "")
  return html
}

import { Loader } from "@googlemaps/js-api-loader"
import { getLoginError, getValidateRequired } from "@/i18n"
import { requestGetUserProfile } from "@/api/user"
import router from "@/router"
import { requestGetCreatorProfile } from "@/api/creator"
export const getGeoLocate = (address) => {
  return new Promise((resolve, reject) => {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      version: "weekly",
      libraries: ["places"],
    })
    loader
      .load()
      .then((google) => {
        const geocoder = new google.maps.Geocoder()
        geocoder
          .geocode({ address: address })
          .then((gcResponse) => {
            if (gcResponse.results?.length > 0) {
              return resolve(gcResponse.results[0]?.geometry?.location)
            } else {
              reject()
            }
          })
          .catch(() => {
            reject()
          })
      })
      .catch((e) => {
        reject()
      })
  })
}

function forceDownload(blob, filename) {
  var a = document.createElement("a")
  a.download = filename
  a.href = blob
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export async function downloadWithUrl(url, filename = "") {
  const appStore = useAppStore()
  appStore.toggleGlobalLoading(true)
  try {
    const response = await fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    })
    const blob = await response.blob()
    const blobUrl = window.URL.createObjectURL(blob)
    forceDownload(blobUrl, filename)
  } catch (error) {
    console.error(error)
  } finally {
    appStore.toggleGlobalLoading(false)
  }
}

export function setFontSize() {
  const clientWidth = localStorage.getItem("windowWidth")
  if (clientWidth) {
    document.querySelector("html").style.fontSize = clientWidth / 10 + "px"
  }
}

export function latLngToAddress(lat, lng) {
  return axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY,
      latlng: lat + "," + lng,
    },
  })
}

export function addressToLatLng(address, language) {
  return axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY,
      address,
      language,
    },
  })
}

export function openGoogleMap(lat, lng, language) {
  window.open(
    `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&hl=${
      language || "ja"
    }`,
  )
}

/**
 * テキストのコピー
 * @param text コピーされたテキスト
 * @param callback コールバックメソッド
 */
export const copyToClipboard = (text, callback) => {
  return new Promise((resolve, reject) => {
    if (text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text)
        if (callback) {
          callback()
        }
        setTimeout(() => {
          resolve()
        })
      } else {
        if (document.execCommand) {
          const textArea = document.createElement("textarea")
          textArea.value = text
          textArea.style.zIndex = "-10000"
          document.body.appendChild(textArea)
          textArea.readOnly = true
          textArea.focus()
          textArea.select()
          if (document.execCommand("copy")) {
            textArea.remove()
            if (callback) {
              callback()
            }
            setTimeout(() => {
              resolve()
            })
          } else {
            reject()
          }
        } else {
          reject()
        }
      }
    } else {
      reject()
    }
  })
}

export const detectIsMobile = () => {
  return /iPhone|Android/i.test(navigator.userAgent)
}

export const detectIsIPhone = () => {
  return /iPhone/i.test(navigator.userAgent)
}

export function openGoogleMapApp(lat, lng, language) {
  const normalUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&hl=${
    language || "ja"
  }`
  const iPhoneUrl = `comgooglemaps://?daddr=${lat},${lng}`
  const a = document.createElement("a")
  a.href = normalUrl
  if (detectIsIPhone()) {
    a.href = iPhoneUrl
  }
  a.target = "_blank"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  const status = {
    hidden: false,
    a: 0,
  }
  if (detectIsIPhone()) {
    status.a = setInterval(() => {
      if (document.hidden) {
        status.hidden = true
        clearInterval(status.a)
      }
    }, 50)
    setTimeout(() => {
      if (status.hidden) return
      clearInterval(status.a)
      const a = document.createElement("a")
      a.href = normalUrl
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }, 3000)
  }
}

export function getAddressInfo(address, language) {
  return axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY,
      address,
      language,
    },
  })
}

export function getTimeScope(startDate, endDate, pattern, language) {
  if (language === "ja") {
    return `${dayjs
      .tz(startDate, "Asia/Tokyo")
      .locale(ja)
      .format(pattern)}〜${dayjs
      .tz(endDate, "Asia/Tokyo")
      .locale(ja)
      .format(pattern)}`
  } else {
    return `${dayjs
      .tz(startDate, "Asia/Tokyo")
      .locale(en)
      .format(pattern)} to ${dayjs
      .tz(endDate, "Asia/Tokyo")
      .locale(en)
      .format(pattern)}`
  }
}

export function isNotEmptyMultiLangObj(obj) {
  return obj && (obj.ja || obj.en)
}

export function getMultiLangValue(obj, locale) {
  if (!obj) {
    return ""
  }
  return obj[locale] || obj.ja || obj.en
}

export function imageToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      if (e.target.result) {
        resolve(e.target.result)
      } else {
        reject("error")
      }
    }
    fileReader.onerror = () => {
      reject("error")
    }
    fileReader.readAsDataURL(file)
  })
}

export function getImageWidthAndHeight(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      if (e.target.result) {
        const image = new Image()
        image.onload = (e) => {
          resolve({
            width: image.width,
            height: image.height,
          })
        }
        image.src = e.target.result
      } else {
        reject("error")
      }
    }
    fileReader.onerror = () => {
      reject("error")
    }
    fileReader.readAsDataURL(file)
  })
}

export function getRequiredRule() {
  return {
    required: true,
    message: getValidateRequired(),
  }
}

export function getMinMaxLenRule(min, max, message) {
  return {
    min,
    max,
    message,
  }
}

export function formatNumeric(obj, prop, max) {
  obj[prop] = (obj[prop] + "")?.replaceAll(/[^0-9]+/g, "")
  if (obj[prop]) {
    if (max) {
      obj[prop] = obj[prop] > max ? max : obj[prop]
    }
    obj[prop] = (+obj[prop]).toLocaleString("en-US")
  }
}

export function getEventStatus(startDateStr, endDateStr) {
  const startDate = dayjs(startDateStr)
  const endDate = dayjs(endDateStr)
  const now = dayjs().tz("Asia/Tokyo")
  if (now.isBefore(startDate)) {
    // coming
    return "coming"
  } else if (now.isAfter(endDate.add(1, "day"))) {
    // finished
    return "finished"
  } else {
    // during
    return "during"
  }
}

/**
 * 获取适合单位的文件大小
 * @param size 文件大小（单位为B的纯数值）
 * @returns 文件大小（自适应MB、KB、B）
 */
export function getFileSize(size) {
  if (size) {
    if (size > 1024 * 1024) {
      return +(size / 1024 / 1024).toFixed(2) + "MB"
    }
    if (size > 1024) {
      return +(size / 1024).toFixed(2) + "KB"
    }
    return size + "B"
  }
  return ""
}

export function getDurationFormat(duration, locale) {
  const mins = Math.floor(duration / 60)
  const seconds = duration % 60
  if (locale === ja) {
    return `${mins}分${seconds}秒`
  } else {
    return `${mins}’${seconds}`
  }
}

const model_3d_type = process.env.VUE_APP_UPLOAD_MODEL_3D_TYPE

export function getFileCategoryByFile(file) {
  if (file) {
    const fileCategory = file.type
    if (fileCategory) {
      if (fileCategory.startsWith("image")) {
        return "image"
      }
      if (fileCategory.startsWith("video")) {
        return "video"
      }
      if (fileCategory.startsWith("audio")) {
        return "audio"
      }
    }
    const fileName = file.name
    const fileExtension = fileName.substring(fileName.lastIndexOf("."))
    if (model_3d_type.includes(fileExtension)) {
      return "model_3d"
    }
    return "other"
  }
  return ""
}

export function getFileCategoryByFileUrl(fileUrl) {
  if (fileUrl) {
    const url = decodeURIComponent(fileUrl)
    const extensionStartIndex = url.lastIndexOf(".")
    const extensionEndIndex =
      url.lastIndexOf("?") === -1 ? url.length : url.lastIndexOf("?")
    const fileExtension = url
      .substring(extensionStartIndex, extensionEndIndex)
      ?.toLowerCase()
    if (fileExtension) {
      if (
        [".jpg", ".jpeg", ".png", ".svg", ".gif"].indexOf(fileExtension) !== -1
      ) {
        return "image"
      }
      if ([".mp4", ".mov", ".webm"].indexOf(fileExtension) !== -1) {
        return "video"
      }
      if ([".mp3", ".wav", ".ogg"].indexOf(fileExtension) !== -1) {
        return "audio"
      }
      if (model_3d_type.includes(fileExtension)) {
        return "model_3d"
      }
      return "other"
    }
  }
  return ""
}

export function getFileNameByFileUrl(fileUrl) {
  if (fileUrl) {
    const url = decodeURIComponent(fileUrl)
    const index = url.lastIndexOf("/")
    if (index !== -1) {
      return url.substring(index + 1)
    }
  }
  return ""
}

export function getFileUrl(file) {
  if (file) {
    return URL.createObjectURL(file)
  }
  return ""
}

export function getFileExtension(file) {
  if (file) {
    const name = file.name
    return name.substring(name.lastIndexOf(".") + 1).toUpperCase()
  }
  throw new Error("File does not exist")
}

export function clearLoginStatus() {
  const {
    isLogin,
    isCreator,
    userInfo,
    creatorInfo,
    currentManagedCreator,
    managedCreators,
  } = storeToRefs(useUserStore())
  isCreator.value = false
  isLogin.value = false
  userInfo.value = {}
  creatorInfo.value = {}
  currentManagedCreator.value = {}
  managedCreators.value = []
  const locale = localStorage.getItem("i18n-locale")
  const previous_notification_ids = localStorage.getItem(
    "previous_notification_ids",
  )
  localStorage.clear()
  localStorage.setItem("i18n-locale", locale)
  if (previous_notification_ids) {
    localStorage.setItem("previous_notification_ids", previous_notification_ids)
  }
  sessionStorage.clear()
}

export function isLink(link) {
  const uriRegex = /^(https|http):\/\/[\w/:%#$&?()~.=+-]+$/
  return uriRegex.test(link)
}

export function initCreatorRequestParam() {
  return {}
}

export async function getUserProfile(isLoginProcess = false) {
  const {
    userInfo,
    creatorInfo,
    currentManagedCreator,
    registerEmail,
    isLogin,
    managedCreators,
    creatorId,
    isCreator,
  } = storeToRefs(useUserStore())
  const { loginRedirect } = storeToRefs(useAppStore())
  try {
    const userProfile = await requestGetUserProfile()
    userInfo.value = userProfile
    registerEmail.value = userProfile.email
    if (userProfile.user_type === 2) {
      const creatorProfile = await requestGetCreatorProfile()
      const creators = creatorProfile.managed_creators
      if (creators?.length > 0) {
        creatorInfo.value = creatorProfile
        currentManagedCreator.value = creators[0]
        creatorId.value = creators[0].creator_id
        managedCreators.value = creators
        isCreator.value = true
      }
    } else {
      isCreator.value = false
      creatorId.value = ""
      creatorInfo.value = {}
      managedCreators.value = []
      currentManagedCreator.value = {}
    }
    isLogin.value = true
    if (isLoginProcess) {
      const resList = await Promise.all([
        requestGetNotification(),
        requestGetUserSetting(),
      ])
      const notifications = resList[0]
      showNotificationHandler(notifications)
      const setting = resList[1]
      showReceiveEmailSettingHandler(setting)
    }
    if (isLoginProcess) {
      if (loginRedirect.value) {
        router.replace(loginRedirect.value)
        loginRedirect.value = null
      } else {
        router.replace({ path: "/" })
      }
    }
  } catch {
    isLoginProcess && Toast(getLoginError())
  } finally {
    isLoginProcess && Toast.clear()
  }
}
export function showNotificationHandler(notifications) {
  const prevNotificationIdsKey = "previous_notification_ids"
  if (
    notifications &&
    Array.isArray(notifications) &&
    notifications.length > 0
  ) {
    const prevNotificationIdsStr = localStorage.getItem(prevNotificationIdsKey)
    const notShowNotificationIds = []
    const prevNotificationIds = []
    // 获取已经展示的通知id列表
    if (prevNotificationIdsStr) {
      const temp = JSON.parse(prevNotificationIdsStr)
      if (temp && Array.isArray(temp) && temp.length > 0) {
        prevNotificationIds.push(...temp)
      }
    }
    // 获取没有展示的通知id列表
    notifications.forEach((notification) => {
      const id = notification.notification_id
      if (!prevNotificationIds.find((prevId) => prevId === id)) {
        notShowNotificationIds.push(id)
      }
    })
    // 展示第一条未展示的通知，并将其保存到已经展示的通知id列表中
    if (notShowNotificationIds.length > 0) {
      const notification = notifications.find(
        (notification) =>
          notification.notification_id === notShowNotificationIds[0],
      )
      if (notification) {
        const notificationIds = [
          ...prevNotificationIds,
          ...notShowNotificationIds,
        ]
        localStorage.setItem(
          prevNotificationIdsKey,
          JSON.stringify(notificationIds),
        )
        const appStore = useAppStore()
        appStore.openNotification(notification)
      }
    }
  }
}
export function filterPaymentNotifications(notifications) {
  if (
    !notifications ||
    !Array.isArray(notifications) ||
    notifications.length === 0
  ) {
    return null
  }
  const paymentNotificationIdsKey = "payment_notification_ids"

  const prevNotificationIdsStr = localStorage.getItem(paymentNotificationIdsKey)
  const notShowNotificationIds = []
  const prevNotificationIds = []
  // 获取已经展示的通知id列表
  if (prevNotificationIdsStr) {
    const temp = JSON.parse(prevNotificationIdsStr)
    if (temp && Array.isArray(temp) && temp.length > 0) {
      prevNotificationIds.push(...temp)
    }
  }
  // 获取没有展示的通知id列表
  notifications.forEach((notification) => {
    const id = notification.notification_id
    if (!prevNotificationIds.find((prevId) => prevId === id)) {
      notShowNotificationIds.push(id)
    }
  })
  // 展示第一条未展示的通知，并将其保存到已经展示的通知id列表中
  if (notShowNotificationIds.length > 0) {
    const notification = notifications.find(
      (notification) =>
        notification.notification_id === notShowNotificationIds[0],
    )
    if (notification) {
      const notificationIds = [
        ...prevNotificationIds,
        ...notShowNotificationIds,
      ]
      localStorage.setItem(
        paymentNotificationIdsKey,
        JSON.stringify(notificationIds),
      )
      return notification
    }
  }
  return null
}

function showReceiveEmailSettingHandler(setting) {
  if (setting.receive_email === null) {
    const appStore = useAppStore()
    appStore.openReceiveEmailSettingDialog()
  }
}

export const abortController = {
  value: new AbortController(),
  abort: () => {
    abortController.value.abort()
    abortController.value = new AbortController()
  },
}

export function getTicketTimeScope(startTime, endTime, pattern, language) {
  if (language === "ja") {
    const start = dayjs(startTime).tz("Asia/Tokyo").locale(ja).format(pattern)
    const end = dayjs(endTime).tz("Asia/Tokyo").locale(ja).format(pattern)
    return `${start}〜${end}`
  }
  const start = dayjs(startTime).tz("Asia/Tokyo").locale(en).format(pattern)
  const end = dayjs(endTime).tz("Asia/Tokyo").locale(en).format(pattern)
  return `${start} - ${end}`
}

export function isEmpty(value) {
  if (value === undefined || value === null) {
    return true
  }
  if (typeof value === "number") {
    return false
  }
  if (typeof value === "string" && value.length === 0) {
    return true
  }
  if (typeof value === "object") {
    if (value instanceof Array && value.length === 0) {
      return true
    }
    if (value instanceof Set && value.size === 0) {
      return true
    }
    if (value instanceof Map && value.size === 0) {
      return true
    }
    if (Object.keys(value) === 0) {
      return true
    }
  }
  return false
}
export function isNotEmpty(value) {
  return !isEmpty(value)
}

export function showSecretNumber(card_no) {
  return card_no.substring(card_no.length - 8)
}

const PAYMENT_PUBLIC_KEY = process.env.VUE_APP_PAYMENT_PUBLIC_KEY

export function encrypt(obj) {
  const crypt = new JSEncrypt()
  crypt.setPublicKey(PAYMENT_PUBLIC_KEY)
  const data = JSON.stringify(obj)
  const result = crypt.getKey().encrypt(data)
  return result
}

export function isElementInViewport(el) {
  const { top, left } = el.getBoundingClientRect()
  return (
    top >= 0 &&
    left >= 0 &&
    top <= document.documentElement.clientHeight &&
    left <= document.documentElement.clientWidth
  )
}

export function destroyModel3DRenderInstance(instance) {
  if (instance) {
    instance.controller = null
    instance.pmremGenerator = null
    instance.environment = null
    instance.renderer = null
    instance.axesHelper = null
    instance.grid = null
    instance.camera = null
    instance.scene = null
    instance = null
  }
}

/**
 *
 * @param {string} url
 * @param {HTMLCanvasElement} canvas
 * @param {{isShowGrid?: boolean; isShowAxesHelper?: boolean; isLoop?: boolean; stopLoop?: boolean; cameraPosition?: {x: number; y: number; z: number}}} config
 */
export function render_3d_model(url, canvas, config) {
  if (!url || !canvas) {
    throw new Error("url or canvas is undefined")
  }
  canvas.width = 343 * 2 * window.devicePixelRatio
  canvas.height = 343 * 2 * window.devicePixelRatio

  let instance = {}
  // 场景
  instance.scene = new THREE.Scene()
  instance.scene.background = new THREE.Color(0xdddddd)
  // 相机
  const ratio = canvas.width / canvas.height
  instance.camera = new THREE.PerspectiveCamera(75, ratio, 0.1, 10000)
  // 地表格
  if (config.isShowGrid) {
    instance.grid = new THREE.GridHelper(500, 100, 0xffffff, 0xffffff)
    instance.grid.material.opacity = 0.5
    instance.grid.material.depthWrite = false
    instance.grid.material.transparent = true
    instance.scene.add(instance.grid)
  }
  // 坐标轴辅助线
  if (config.isShowAxesHelper) {
    instance.axesHelper = new THREE.AxesHelper(15)
    instance.scene.add(instance.axesHelper)
  }
  // 渲染器
  instance.renderer = new THREE.WebGLRenderer({ canvas })
  // 环境
  instance.environment = new RoomEnvironment()
  instance.pmremGenerator = new THREE.PMREMGenerator(instance.renderer)
  instance.scene.environment = instance.pmremGenerator.fromScene(
    instance.environment,
  ).texture
  // 控制器
  instance.controller = new OrbitControls(
    instance.camera,
    instance.renderer.domElement,
  )
  // 循环渲染
  if (config.isLoop) {
    const animate = () => {
      if (!config.stopLoop && instance?.renderer) {
        instance.renderer.render(instance.scene, instance.camera)
        requestAnimationFrame(animate)
      } else {
        destroyModel3DRenderInstance(instance)
      }
    }
    animate()
  } else {
    instance.renderer.render(instance.scene, instance.camera)
  }
  // 加载器
  const loader = new GLTFLoader()
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (gltf) => {
        gltf.scene.position.set(0, 0, 0)
        const model = gltf.scene
        instance.scene.add(model)
        const box = new THREE.Box3().setFromObject(model)
        const center = new THREE.Vector3()
        box.getCenter(center)
        model.position.sub(center)
        const distance = box.getSize(new THREE.Vector3()).length()
        instance.camera.position.set(
          center.x + distance / 2,
          center.y + distance / 2,
          center.z + distance,
        )
        instance.renderer.render(instance.scene, instance.camera)
        instance.camera.lookAt(0, 0, 0)
        resolve(instance)
      },
      undefined,
      (err) => {
        reject(err)
      },
    )
  })
}

export async function get_model_3d_thumbnail_file(file, cameraPosition) {
  const canvas = document.createElement("canvas")
  canvas.style.width = 343 + "px"
  canvas.style.height = 343 + "px"
  const renderInstance = await render_3d_model(getFileUrl(file), canvas, {
    cameraPosition,
  })

  const base64 = canvas.toDataURL("image/jpeg")
  destroyModel3DRenderInstance(renderInstance)

  const bytes = window.atob(base64.split(",")[1])
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: "image/jpeg" })
  const index = file.name?.lastIndexOf(".")
  const newName = `${file.name?.substring(0, index)}_thumbnail.jpg`
  const newFile = new File([blob], newName, { type: "image/jpeg" })
  return newFile
}

export async function get_model_3d_thumbnail_file_by_file_url(
  fileUrl,
  cameraPosition,
) {
  const canvas = document.createElement("canvas")
  canvas.style.width = 1024 + "px"
  canvas.style.height = 1024 + "px"

  const renderInstance = await render_3d_model(fileUrl, canvas, {
    cameraPosition,
  })

  const base64 = canvas.toDataURL("image/jpeg")
  destroyModel3DRenderInstance(renderInstance)

  const url = decodeURIComponent(fileUrl)
  const fileNameStartIndex = url.lastIndexOf(".")
  const fileNameEndIndex =
    url.lastIndexOf("?") === -1 ? url.length : url.lastIndexOf("?")
  const fileName = url.substring(fileNameStartIndex, fileNameEndIndex)
  const bytes = window.atob(base64.split(",")[1])
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: "image/jpeg" })
  const newName = `${fileName || "file"}_thumbnail.jpg`
  const newFile = new File([blob], newName, { type: "image/jpeg" })
  return newFile
}

/**
 *
 * @param {"Android" | "iPhone" | "PC" | "iOS"} deviceType
 */
export function isSpecifyDevice(deviceType) {
  const userAgent = window.navigator.userAgent

  if (deviceType === "Android") {
    return /Android/.test(userAgent)
  }

  if (deviceType === "iOS") {
    return /iPhone|iPad|iPod/.test(userAgent)
  }

  if (deviceType === "iPhone") {
    return /iPhone/.test(userAgent)
  }

  if (deviceType === "iPad") {
    return /iPad/.test(userAgent)
  }

  if (deviceType === "PC") {
    return !/Android|iPhone|iPad|iPod/.test(userAgent)
  }

  throw new Error("device not found")
}

export function convertTimezoneToJapan(date) {
  return dayjs.tz(date, "Asia/Tokyo").toDate()
}

/**
 * open link in browser new tab
 * @param {string} link
 */
export function openInNewTab(link) {
  const aEl = document.createElement("a")
  aEl.href = link
  aEl.target = "_blank"
  aEl.style.display = "none"
  document.body.appendChild(aEl)
  aEl.click()
  aEl.parentElement.removeChild(aEl)
}

export function getPxToRemRatio() {
  const rootFontSize = window.getComputedStyle(
    document.documentElement,
  ).fontSize
  const pxToRemRatio =
    +rootFontSize.substring(0, rootFontSize.length - 2) / 37.5
  return pxToRemRatio
}
