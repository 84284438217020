import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import vconsoleMixin from "@/utils/vconsole"
import pinia from "@/store/index"
import VueGtag from "vue-gtag"
import { createMetaManager } from "vue-meta"
import "@/assets/styles/index.less"
import "@/assets/styles/pc-first.less"
import "./rem"
import "@vant/touch-emulator"
import { getLocale } from "./i18n"
import { createI18n } from "vue-i18n"
import { Lazyload } from "vant"
import messages from "./i18n"

let app = createApp(App)

const metaManager = createMetaManager(false, {
  meta: { tag: "meta", nameless: true },
})
app.use(metaManager)

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  messages,
})
app.use(i18n)

app.use(Lazyload)

vconsoleMixin()

const gtagConf = {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY,
  },
}
if (!gtagConf.config.id) {
  app.use(pinia()).use(router).mount("#app")
} else {
  app.use(pinia()).use(router).use(VueGtag, gtagConf, router).mount("#app")
}
