import { detectIsMobile } from "./utils/tool"

const setRem = () => {
  const clientHeight = window.innerHeight
  const clientWidth = window.innerWidth
  const vh = clientHeight / 100
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  if (clientWidth && clientHeight && detectIsMobile()) {
    document.querySelector("html").style.fontSize = clientWidth / 10 + "px"
  } else {
    document.querySelector("html").style.fontSize =
      (clientHeight * 9) / 16 / 10 + "px"
    document.documentElement.style.setProperty("--vw", `${(vh * 9) / 16}px`)
  }
}

setRem()

window.onresize = () => {
  setRem()
  // https://stackoverflow.com/questions/57281142/calling-window-innerheight-upon-window-resize-event-doesnt-give-correct-height
  setTimeout(() => {
    setRem()
  }, 100)
}

window.onpageshow = (e) => {
  if (e.persisted) {
    setTimeout(() => {
      setRem()
    })
  }
}

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "visible") {
    setRem()
  }
})
