import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-659d53c5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "notification-container"
};
import { useAppStore } from "@/store/modules/app";
import { storeToRefs } from "pinia";
import { getMultiLangValue } from "@/utils/tool";
import { useI18n } from "vue-i18n";
import ButtonView from "./ButtonView.vue";
export default {
  __name: 'NotificationDialog',
  setup(__props) {
    const {
      locale
    } = useI18n();
    const {
      showNotification,
      notification
    } = storeToRefs(useAppStore());
    const appStore = useAppStore();
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(_Popup), {
        show: _unref(showNotification),
        class: "notification-popup",
        "close-on-click-overlay": "",
        "before-close": () => {
          _unref(appStore).closeNotification();
        }
      }, {
        default: _withCtx(() => [_createElementVNode("section", _hoisted_1, [_createElementVNode("header", null, _toDisplayString(_unref(getMultiLangValue)(_unref(notification)?.title, _unref(locale))), 1), _createElementVNode("main", null, _toDisplayString(_unref(getMultiLangValue)(_unref(notification)?.content, _unref(locale))), 1), _createElementVNode("footer", null, [_createVNode(ButtonView, {
          round: "",
          class: "w-full",
          onClick: _cache[0] || (_cache[0] = $event => _unref(appStore).closeNotification())
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("notification.ok")), 1)]),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show", "before-close"]);
    };
  }
};