import "vant/es/locale/style";
import _Locale from "vant/es/locale";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2359d332"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "pc-first"
};
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n";
import { watch, computed, onMounted, ref } from "vue";
import ja from "vant/es/locale/lang/ja-JP";
import en from "vant/es/locale/lang/en-US";
import { RouterView } from "vue-router";
import { ElConfigProvider } from "element-plus";
import elEn from "element-plus/dist/locale/en.mjs";
import elJa from "element-plus/dist/locale/ja.mjs";
import { useRoute } from "vue-router";
import LayoutView from "@/views/pc-first/layout/index.vue";
import { detectIsMobile } from "./utils/tool";
import { useUserStore } from "@/store/modules/user";
import { storeToRefs } from "pinia";
import { getUserProfile } from "@/utils/tool";
import { useAppStore } from "./store/modules/app";
import NotificationDialog from "./components/NotificationDialog.vue";
import ReceiveEmailSettingDialog from "./components/ReceiveEmailSettingDialog.vue";
import { watchEffect } from "vue";
// preload get finished gif
import finishedIcon from "@/assets/img/get-finished.gif?raw";
export default {
  __name: 'App',
  setup(__props) {
    const preloadFinishedGif = new Image();
    preloadFinishedGif.src = finishedIcon;
    const route = useRoute();
    watch(() => route.fullPath, () => {
      document.querySelector(".main")?.scrollTo({
        top: 0
      });
    });
    const {
      t,
      locale
    } = useI18n();
    const getMeta = () => {
      return {
        title: t("meta.title"),
        meta: {
          name: "description",
          content: t("meta.description")
        }
      };
    };
    const {
      meta
    } = useMeta(getMeta());
    watch(() => locale.value, next => {
      meta.title = getMeta().title;
      meta.meta = getMeta().meta;
      if (next === "ja") {
        _Locale.use("ja-JP", ja);
      } else {
        _Locale.use("en-US", en);
      }
    });
    const elLocale = computed(() => {
      if (locale.value === "ja") {
        return {
          name: elJa.name,
          el: {
            ...elJa.el,
            select: {
              ...elJa.el.select,
              placeholder: "選択なし"
            }
          }
        };
      }
      return {
        name: elEn.name,
        el: {
          ...elEn.el,
          select: {
            ...elEn.el.select,
            placeholder: "Not Selected"
          }
        }
      };
    });
    const {
      isLogin
    } = storeToRefs(useUserStore());
    const appStore = useAppStore();
    const isLoading = ref(true);
    async function init() {
      try {
        isLoading.value = true;
        if (isLogin.value) {
          appStore.toggleGlobalLoading(true);
          await getUserProfile();
        }
      } finally {
        isLoading.value = false;
        appStore.toggleGlobalLoading(false);
      }
    }
    watchEffect(() => {
      if (isLogin.value) {
        let metaThemeColor = document.querySelector('meta[name="theme-color"]');
        if (!metaThemeColor) {
          metaThemeColor = document.createElement("meta");
          metaThemeColor.setAttribute("name", "theme-color");
          document.head.appendChild(metaThemeColor);
        }
        metaThemeColor.setAttribute("content", "#6FE05E");
      } else {
        const metaThemeColor = document.querySelector('meta[name="theme-color"]');
        if (metaThemeColor) {
          metaThemeColor.parentNode.removeChild(metaThemeColor);
        }
      }
    });
    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_metainfo = _resolveComponent("metainfo");
      return !isLoading.value ? (_openBlock(), _createBlock(_unref(ElConfigProvider), {
        key: 0,
        locale: elLocale.value
      }, {
        default: _withCtx(() => [_ctx.$route.meta.isFullScreen === true ? (_openBlock(), _createBlock(_unref(RouterView), {
          key: 0
        })) : _ctx.$route.meta.pcFirst ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(LayoutView)])) : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["h-full", {
            'sp-first': !_unref(detectIsMobile)()
          }])
        }, [_createVNode(_unref(RouterView), null, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        })], 2)), _createVNode(_component_metainfo, null, {
          title: _withCtx(({
            content
          }) => [_createTextVNode(_toDisplayString(content), 1)]),
          _: 1
        }), _createVNode(NotificationDialog), _createVNode(ReceiveEmailSettingDialog)]),
        _: 1
      }, 8, ["locale"])) : _createCommentVNode("", true);
    };
  }
};