import { defineStore } from "pinia"
import { ElLoading } from "element-plus"
import { useLocalStorage } from "@vueuse/core"

export const useAppStore = defineStore("app", {
  state: () => ({
    globalLoading: null,
    globalLoadingStatus: false,
    timer: null,
    showNotification: false,
    notification: null,
    showReceiveEmailSettingDialog: false,
    loginRedirect: null,
    paymentCard: useLocalStorage("paymentCard", {}),
    purchaseArtwork: useLocalStorage("purchaseArtwork", {}),
    abortRequest: false,
    model_3d_camera_position: null,
    transferArtworkInfo: useLocalStorage("transferArtworkInfo", {}),
  }),
  actions: {
    toggleGlobalLoading(isLoading = true) {
      clearTimeout(this.timer)
      this.globalLoadingStatus = isLoading
      if (isLoading) {
        this.globalLoading = ElLoading.service({ fullscreen: true })
        window.addEventListener("keydown", preventClick)
      } else {
        this.timer = setTimeout(() => {
          this.globalLoading && this.globalLoading.close()
          window.removeEventListener("keydown", preventClick)
        }, 100)
      }
    },
    openNotification(notification) {
      this.notification = notification
      this.showNotification = true
    },
    closeNotification() {
      this.showNotification = false
      setTimeout(() => {
        this.notification = null
      }, 500)
    },
    openReceiveEmailSettingDialog() {
      this.showReceiveEmailSettingDialog = true
    },
    closeReceiveEmailSettingDialog() {
      this.showReceiveEmailSettingDialog = false
    },
    toggleAbortRequest(abort) {
      this.abortRequest = abort
    },
  },
})

function preventClick(e) {
  const preventKeyList = ["Enter", "Tab"]
  if (preventKeyList.includes(e.code)) {
    e.preventDefault()
  }
}
