import axios from "axios"
import { Toast, Dialog } from "vant"
import router from "@/router"
import { login } from "@/api/base/login_signup_logout"
import { getLocale, getOutlineError, getErrorTitle, getErrorOK } from "@/i18n"
import { clearLoginStatus, getUserProfile } from "@/utils/tool"
import { ElMessage } from "element-plus"
import { useUserStore } from "@/store/modules/user"

let instance = axios.create()
let needShowErrMsg = false
let isNetworkOnline = true
// config
instance.defaults.timeout = 60 * 1000
instance.defaults.headers["X-Requested-With"] = "XMLHttpRequest"
instance.defaults.headers.post["Content-Type"] =
  "application/json;application/x-www-form-urlencoded;charset=utf8;multipart/form-data"
instance.defaults.baseURL = process.env.VUE_APP_BASE_URL

// intercept request
instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      `Bearer ${localStorage.getItem("token")}` || ""
    config.headers["Accept-Language"] = getLocale()
    return config
  },
  (error) => {
    return error
  },
)

/**
 *
 */
async function noAuthHandler(response) {
  response.data.message && ElMessage.error(response.data.message)
  const userStore = useUserStore()
  if (userStore.isLogin) {
    await getUserProfile()
  } else {
    clearLoginStatus()
  }
  router.replace("/")
}

// intercept response
instance.interceptors.response.use(
  (result) => {
    if (result.status === 200) {
      if (result.data) {
        return Promise.resolve(result.data)
      } else {
        return Promise.reject(result)
      }
    } else {
      return Promise.reject(result)
    }
  },
  (err) => {
    Toast.clear()
    if (err.message === "canceled") {
      return Promise.reject(err)
    }
    if (!isNetworkOnline || !err.response) {
      Toast(getOutlineError(true))
    } else if (err.response?.status === 401) {
      clearLoginStatus()
      setTimeout(() => {
        login()
      })
    } else if (err.response?.status === 502 || err.response?.status === 503) {
      router.replace({
        path: "/maintenance",
        query: {
          start_datetime: err.response?.data?.start_datetime,
          end_datetime: err.response?.data?.end_datetime,
        },
      })
    } else if (err.response?.status === 403) {
      noAuthHandler(err.response)
      return
    } else if (err.response?.status === 404) {
      Dialog.alert({
        title: getErrorTitle(true),
        message: "404 Not Found",
        confirmButtonColor: "#000000",
        confirmButtonText: getErrorOK(true),
        beforeClose: async () => {
          router.replace({ path: "/" }).then(() => {
            window.location.reload()
          })
          return true
        },
      })
    } else if (err.response?.data?.message && needShowErrMsg) {
      if (err.response?.data?.message?.length > 0) {
        Dialog.alert({
          title: getErrorTitle(true),
          message: err.response?.data?.message,
          confirmButtonColor: "#000000",
          confirmButtonText: getErrorOK(true),
        })
      }
    }
    return Promise.reject(err)
  },
)

window.addEventListener(
  "online",
  function () {
    isNetworkOnline = true
  },
  true,
)
window.addEventListener(
  "offline",
  function () {
    Toast(getOutlineError(true))
    isNetworkOnline = false
  },
  true,
)

// Post
export const postRequest = (
  path,
  params,
  needLoading = true,
  needShowErr = false,
) => {
  needShowErrMsg = needShowErr
  if (needLoading) {
    Toast.loading({
      duration: 0,
    })
  }
  return new Promise((resolve, reject) => {
    instance
      .post(path, params)
      .then((res) => {
        if (needLoading) {
          Toast.clear()
        }
        if (res?.code === "OK") {
          if (res.data) {
            resolve(res.data)
          } else {
            resolve({})
          }
        } else {
          reject(res)
        }
      })
      .catch((err) => {
        reject(err.message)
      })
  })
}

// Get
export const getRequest = (
  path,
  params,
  needLoading = true,
  needShowErr = false,
  config = {},
) => {
  needShowErrMsg = needShowErr
  if (needLoading) {
    Toast.loading({
      duration: 0,
    })
  }
  return new Promise((resolve, reject) => {
    instance
      .get(path, {
        params: params,
        ...config,
      })
      .then((res) => {
        if (needLoading) {
          Toast.clear()
        }
        if (res?.code === "OK") {
          if (res.data) {
            resolve(res.data)
          } else {
            resolve({})
          }
        } else {
          reject(res)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Put
export const putRequest = (
  path,
  params,
  needLoading = true,
  needShowErr = false,
) => {
  needShowErrMsg = needShowErr
  if (needLoading) {
    Toast.loading({
      duration: 0,
    })
  }
  return new Promise((resolve, reject) => {
    instance
      .put(path, params)
      .then((res) => {
        if (needLoading) {
          Toast.clear()
        }
        if (res?.code === "OK") {
          if (res.data) {
            resolve(res.data)
          } else {
            resolve({})
          }
        } else {
          reject(res)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
